@import "~@blueprintjs/core/lib/scss/variables";

/* Tooltip container */
.tooltip {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  padding: 0.5rem;
  box-shadow: $pt-elevation-shadow-1;
}
