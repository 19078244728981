@import "~@blueprintjs/core/lib/scss/variables";

.selectorBar {
  display: flex;
  flex-direction: row;
}

.container {
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.nonIdealContainer {
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  height: auto !important;
  flex-grow: 1;
}
