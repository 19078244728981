@import "~@blueprintjs/core/lib/scss/variables";

:root {
  --font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  scrollbar-color: $gray5 transparent;
  scrollbar-width: 1.25rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Custom scrollbar configuration */
* ::-webkit-scrollbar {
  height: 1.25rem;
  width: 1.25rem;
}

* ::-webkit-scrollbar-track {
  background-color: transparent;
  border: 0.0625px solid $pt-divider-black;
}

* ::-webkit-scrollbar-thumb {
  background-color: $gray5;
  background-clip: content-box;
  border: 0.375rem solid transparent;
  border-radius: 1.25rem;
}

* ::-webkit-scrollbar-thumb:hover {
  background-color: $gray4;
}

b {
  font-weight: 600 !important; /* Semi-bold */
}

/* Make the first div after navbar fill the viewport below navbar */
#navbar + div {
  width: 100%;
  height: calc(100vh - 3.125rem);
  margin: 0;
}

/* Fixes issue with selector icon overlapping with the label */
.bp3-datepicker-month-select {
  & select {
    width: auto !important;
  }
  & span {
    right: 0 !important;
  }
}

.bp3-datepicker-year-select {
  & select {
    width: auto !important;
  }
  & span {
    right: 0 !important;
  }
}

/* Fixes overflow issue with annotorious popup dialog */
.mosaic-window, .mosaic-preview {
  overflow: visible
}

.mosaic-window .mosaic-window-body, .mosaic-preview .mosaic-window-body {
  overflow: visible;
  z-index: inherit;
}

.mosaic-window .mosaic-preview, .mosaic-preview .mosaic-preview {
  z-index: -1;
}

.mosaic-window-body {
  overflow-y: initial !important;
}

/* React Swiper Styling Overrides */
.swiper-button-prev {
    color: #a7b6c2;
}

.swiper-button-next {
    color: #a7b6c2;
    padding-right: 35px;
}

.swiper-pagination-bullet-active {
    background: #656d76;
}

.swiper-wrapper {
    box-sizing: border-box !important;
}

.td {
  word-wrap:break-word
}

/* Disable drag on images */
img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* Icons in help dialogs */
.helpIcon, .listIcon {
  position: relative;
  bottom: 3px;
  right: 2px;
  margin-left: 7px;
  margin-right: 2px;
}

.listIcon {
  margin-right: 5px;
}
