@import "~@blueprintjs/core/lib/scss/variables";

.stats {
  width: 60%;
  margin-top: 5vh;
  margin-left: 20%;
  margin-bottom: 3vh;
  text-align: left;
  color: white;
}

.left {
  width: 100%;
  height: 100%;
}

.right {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 1rem;
  align-items: center;
}

.download:hover {
  text-decoration: none;
}

.divider {
  margin: 1.5rem 0rem;
  border: 0.0625rem solid $light-gray1;
}
