@import "~@blueprintjs/core/lib/scss/variables";

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: auto;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  margin: 0rem 1rem;
  margin-top: 1rem;
  height: 1.875rem;
  flex-grow: 0;
}

.thumbnail {
  display: flex;
  justify-content: center;
  margin: 1rem 1rem;
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  height: 16rem;
  flex-grow: 0;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.table {
  margin: 0rem 1rem;
  overflow: scroll;
  flex-grow: 1;
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  margin-bottom: 1rem;
  & > table {
    width: 100%;
  }
}
