@import "~@blueprintjs/core/lib/scss/variables";

/* Wrapper container that houses the measurements table */
.container {
  padding: 0rem;
  min-height: 2rem;
  max-height: 15rem;
  overflow-y: auto;
  text-align: center;
  margin: 0rem 0.5rem 0.25rem 0.5rem;
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  & > table {
    width: 100%;
  }
  & p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;
  }
}