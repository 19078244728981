@import "~@blueprintjs/core/lib/scss/variables";

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 0.5rem !important;

  box-shadow: none !important;
  background-color: $light-gray4 !important;
  border-radius: $pt-border-radius !important;
  border: 0.0625rem solid $pt-divider-black;
}

.header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-align: center;
  width: 100%;
  padding-bottom: 0.5rem;
}

.tooltip {
  color: $pt-text-color-muted;
  display: flex !important;
  align-items: center;
}

.input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
