@import "~@blueprintjs/core/lib/scss/variables";

.container {
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  padding: 0.625rem 0rem;
  margin-top: 0.25rem;
  height: 100%;
}

.fullscreen {
  width: 100vw;
  height: calc(100vh - 5rem);
  background-color: $white;
}