@import "~@blueprintjs/core/lib/scss/variables";

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.header {
  background-color: $light-gray5 !important;
  padding: 1rem 1rem 0.5rem 1rem;
  position: sticky;
  position: -webkit-sticky;
  top: 0rem;
  z-index: 2;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5rem !important;

  box-shadow: none !important;
  background-color: $light-gray4 !important;
  border-radius: $pt-border-radius !important;
  border: 0.0625rem solid $pt-divider-black;
}

.checkbox {
  margin-bottom: 0 !important;
}

.cards {
  padding: 0.5rem 1rem 1rem 1rem;
}
