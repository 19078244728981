@import "~@blueprintjs/core/lib/scss/variables";

.container {
  background-color: $white;
  padding: 1rem;
}

.toolbar {
  background-color: $pt-app-background-color;
  border: 0.0625rem solid $pt-divider-black;
  border-radius: $pt-border-radius;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
