@import "~@blueprintjs/core/lib/scss/variables";

/* Utility functions */
@function boxShadow($i) {
  @return inset 0 0 0 0.0625rem rgba(16, 22, 26, $i),
    inset 0 -0.0625rem 0 rgba(16, 22, 26, $i/2);
}

@function backgroundGradient($i) {
  @return linear-gradient(
    to bottom,
    rgba(255, 255, 255, $i),
    rgba(255, 255, 255, 0)
  );
}

/* Annotorious Styling Overrides */
.r6o-editor {
  line-height: 0 !important;
  & .r6o-arrow::after {
    background-color: $light-gray5 !important;
  }
}

.r6o-editor-inner {
  background-color: $light-gray5 !important;
  border-radius: $pt-border-radius !important;
  box-shadow: $pt-elevation-shadow-3 !important;
  padding-top: 0.5rem;
  & > * {
    font-family: var(--font-family);
  }
}

.r6o-btn {
  /* Ok Button */
  height: 1.875rem;
  border-radius: $pt-border-radius;
  border: none;
  background-color: $blue3;
  background-image: backgroundGradient(0.1);
  box-shadow: boxShadow(0.4);
  color: $white;
  &:hover {
    background-color: $blue2;
    box-shadow: boxShadow(0.4);
  }
  /* Cancel Button */
  &.outline {
    background-color: $light-gray5;
    border: none;
    background-image: backgroundGradient(0.8);
    box-shadow: boxShadow(0.2);
    color: #182026;
  }
  &.outline:hover {
    background-clip: padding-box;
    background-color: $light-gray4;
    box-shadow: boxShadow(0.2);
  }
}

/* Delete Button */
.r6o-editor .r6o-footer .r6o-btn.delete-annotation {
  height: 1.875rem;
  width: 1.875rem;
  background-color: $red3;
  border-radius: $pt-border-radius;
  background-image: backgroundGradient(0.1);
  box-shadow: boxShadow(0.4);
  color: $white;
  & :hover {
    background-color: $red2;
    box-shadow: boxShadow(0.4);
  }
  & > svg:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

/* Tag Container */
.r6o-widget.r6o-tag {
  background-color: $light-gray5;
  padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  & input::placeholder {
    color: $pt-text-color-muted !important;
  }
  & .r6o-autocomplete {
    line-height: 22px !important;
  }
  & .r6o-autocomplete li {
    border-radius: $pt-border-radius !important;
  }
  & .r6o-autocomplete li:hover {
    background-color: $light-gray3 !important;
  }
}

/* Tag */
.r6o-widget.r6o-tag ul.r6o-taglist li {
  border-radius: $pt-border-radius;
  margin-left: 0;
  margin-right: 0.25rem;
  & .r6o-delete-wrapper {
    height: 100%;
    background-color: $red3;
    background-image: backgroundGradient(0.1);
    box-shadow: boxShadow(0.4);
    color: $white;
    text-align: center;
  }
  & .r6o-delete-wrapper .r6o-delete {
    padding: 0rem;
  }
  & .r6o-delete-wrapper svg {
    vertical-align: middle;
  }
}

/* Autocomplete dropdown container */
.r6o-autocomplete ul {
  padding: 0.25rem !important;
  border-radius: $pt-border-radius;
}

/* Dotted annotation outline */
svg.a9s-annotationlayer .a9s-annotation .a9s-inner {
  stroke-width: 4;
  stroke: transparent;
  stroke-dasharray: 5;
}

/* Dim mask to highlight selected region */
svg.a9s-annotationlayer .a9s-selection-mask {
  fill: rgba(0, 0, 0, 0.3);
}

/* Disable moving vertices */
.a9s-handle {
  display: none;
}
