@import "~@blueprintjs/core/lib/scss/variables";

.history {
  min-height: 100px;
  margin: 0 auto 2rem auto;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.container {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.nonIdeal {
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  padding: 1rem 0rem;
  margin-top: 1rem;
  padding-top: 0rem;
}

.center {
  text-align: center;
  font-style: italic;
  font-size: medium;
  margin: 0.5rem 0rem;
}
