@import "~@blueprintjs/core/lib/scss/variables";

.label {
  text-anchor: middle;
}

.selectorBar {
  display: flex;
  flex-direction: row;
  margin-top: 0.25rem;
  align-content: stretch;
  /* There wasn't a better way :( */
  & > div {
    & > div {
      height: 100%;
      & > div {
        height: 100%;
        & > button {
          height: 100%;
        }
      }
    }
  }
}


@media (max-width: 1000px) {
  .selectorBar {
    flex-direction: column;
  }
}

.featureButton {
  margin-left: auto;
}

.switch {
  margin: 0rem 0rem !important;
  flex-grow: 1;
}

.divider {
  margin-bottom: 0.5rem !important;
}

.filterButton {
  margin-right: 0.625rem;
}

.statisticsContainer {
  margin-top: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.graphSummaryContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.regionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.regionSwitches {
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  align-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem;
}

.regionSwitchDivider {
  margin: 0rem 0.5rem !important;
}

.collapse {
  margin-bottom: 0.5rem;
}

.nonIdealContainer {
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  padding: 1rem 0rem;
  margin-top: 1rem;
  padding-top: 0rem;
}

.tooltip {
  color: $pt-text-color-muted;
  position: relative;
  bottom: 0.125rem;
  margin-left: 0.5rem;
  margin-right: 0.25rem;
}
