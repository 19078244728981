@import "~@blueprintjs/core/lib/scss/variables";

.column {
  width: 100%;
}

.imageName {
  text-align: center;
}

.mainContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.subContainer {
  margin-top: 2rem;
  margin-right: 20%;
  margin-left: 20%;
  margin-bottom: 2rem;
}

.logo {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.5rem;
}

.heading {
  font-weight: 700 !important;
  margin-bottom: 1rem !important;
}

.brandingContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.description {
  font-style: italic;
  font-size: medium;
  color: $pt-text-color-muted;
  text-align: center;
}

.divider {
  margin: 0.5rem 0rem;
  border: 0.0625rem solid $light-gray3;
}

.uploadHeader {
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-align: center;

  width: 100%;
  padding-bottom: 0.5rem;
}

.tooltip {
  color: $pt-text-color-muted;
  align-items: center;

  &Flex {
    @extend .tooltip;
    display: flex !important;
  }
}

.centerContainer {
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: medium;
  margin: 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.demoButton {
  margin-top: 0.5rem;
  width: 50%;
}

.uploadCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem !important;

  box-shadow: none !important;
  background-color: $light-gray4 !important;
  border-radius: $pt-border-radius !important;
  border: 0.0625rem solid $pt-divider-black;
}

.uploadContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.uploadIcon {
  color: $gray3;
  margin-right: 0.5rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.container h4 {
  margin-top: 0;
}

.uploadButton {
  max-width: 50%;
}

.submit {
  max-width: 50%;
}

.demo {
  color: blue;
  cursor: pointer;
}

.right {
  width: 100%;
  height: 100%;
  overflow: auto;
}
