@import "~@blueprintjs/core/lib/scss/variables";

.toolbar {
  margin: 1rem 1rem;
  margin-bottom: 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewerButton {
  display: inline-flex !important;
}

.viewer {
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.switch {
  margin: 0rem 0rem !important;
  flex-grow: 1;
}

.switchWrapper {
  display: flex;
  align-items: center;
  margin: 0.25rem 0.625rem;
}

.dot {
  display: inline-block;
  margin-left: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.divider {
  margin-bottom: 0.5rem !important;
}

.pane {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
