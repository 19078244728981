@import "~@blueprintjs/core/lib/scss/variables";

.container {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem !important;
  height: 5rem;
  margin-bottom: 0.5rem;

  box-shadow: $pt-elevation-shadow-1 !important;
  background-color: $white !important;
  border-radius: $pt-border-radius !important;
}

.checkbox {
  margin-bottom: 0 !important;
}

.thumbnail {
  display: flex;
  background-color: $white;
  border-radius: $pt-border-radius;
  border: 0.0625rem solid $pt-divider-black;
  height: 4rem !important;
  width: 4rem !important;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.information {
  margin: 0 0.5rem;
  height: 4rem;
  flex-grow: 1;
  & > h6 {
    margin-bottom: 0.25rem !important;
  }
  & > span {
    margin-bottom: 0.25rem !important;
    color: $pt-text-color-muted !important;
  }
  & > p {
    color: $gray3;
  }
}

.progress {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  width: 20%;
  margin-right: 1rem !important;
  margin-top: 0rem;
  flex-grow: 0;
}
